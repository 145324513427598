<template>
  <div style="color: #ffffff;">
    <van-dialog close-on-click-overlay style="background-color: #242a37;" :show-confirm-button="false"
                v-model="$root.userRegisterDialogShow">
      <div style="padding: 20px;">
        <div class="welcomeInfoPc">会员注册</div>
        <div>
          <input type="text" v-model="$root.from.userName" maxlength="35" max="35" class="userInfoInput"
                 placeholder="请输入账号可用数字或字母"/>
        </div>
        <div>
          <input type="text" v-model="$root.from.password" maxlength="35" max="35" class="userInfoInput"
                 placeholder="请输入密码"/>
        </div>
        <div>
          <input type="text" v-model="$root.from.pin" maxlength="35" max="35" class="userInfoInput"
                 placeholder="安全码,用于找回密码,请勿和密码相同"/>
        </div>
        <div style="margin-top: 10px;">
          <van-button block :disabled="submitBtnDisabled" type="warning" @click="onUserRegister">注册</van-button>
        </div>

        <div class="itemCenter" style="margin-top: 10px;">
          <div style="flex: 1;cursor: pointer;"
               @click="$root.userRegisterDialogShow=false;$root.userLoginDialogShow=true;form={}">
            老用户点此登陆
          </div>
          <div
              @click="$root.getPasswordDialogShow = true;$root.userLoginDialogShow=false;$root.userRegisterDialogShow=false;"
              style="flex: 1;text-align: right;cursor: pointer;">
            找回密码
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {Toast} from "vant";
import request from "@/utils/request";

export default {
  name: "register",
  data() {
    return {
      submitBtnDisabled: false,
    }
  }, mounted() {
  }, methods: {
    onUserRegister() {
      if (!this.checkForm()) {
        return;
      }

      if (!this.$root.from.userName || this.$root.from.userName.length < 5) {
        Toast("用户名不低于5个字符");
        return;
      }

      if (!this.checkString(this.$root.from.userName)) {
        Toast("用户名只能是数字或者字母");
        return;
      }

      if (!this.$root.from.password || this.$root.from.password.length < 5) {
        Toast("密码不低于5个字符");
        return;
      }

      if (!this.$root.from.pin || this.$root.from.pin.length < 5) {
        Toast("安全码不低于5个字符,用于找回密码");
        return;
      }
      this.submitBtnDisabled = true;


      let superiorId = localStorage.getItem("superiorId");
      this.$root.from.superiorId = superiorId;
      this.$root.from.tag = localStorage.getItem("tag");

      request.post('/view/userRegister', this.$root.from).then(res => {
        if (res.code === 200) {
          Toast("注册成功,已自动登陆");
          this.$root.userRegisterDialogShow = false;
          this.$root.userLoginDialogShow = false;
          let userInfo = res.data;
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          this.$root.userInfo = userInfo;

          //跳转到个人中心
          this.$router.push("/recharge");

          //如果有需要跳转的页面就先跳转
          // let openPath = localStorage.getItem("openPath");
          // if(openPath && openPath.length>0){
          //   this.$router.push(openPath);
          // }else{
          //   //如果是播放页面就直接跳转到 充值页面
          //   if(this.$route.path==='/videoInfo'){
          //     this.$router.push("/recharge");
          //   }else {
          //     location.reload();
          //   }
          // }

        } else {
          this.$toast.fail(res.message);
        }
      })
      this.submitBtnDisabled = false;
    },
    checkForm() {
      if (!this.$root.from.userName || this.$root.from.userName.length < 5 || this.$root.from.userName.length > 16) {
        Toast("用户名为5-16个字符");
        return false;
      }
      if (!this.$root.from.password || this.$root.from.password.length < 5) {
        Toast("密码不低于5个字符");
        return false;
      }
      return true;
    },
    checkString(str) {
      let regex = /^[0-9a-zA-Z]+$/;
      return regex.test(str);
    }


  }
}
</script>

<style scoped>

</style>