<template>
  <div>

    <div style="margin-bottom: 80px;text-align: center;margin-left: 5px;margin-right: 5px;">
      <img src="@/assets/img/logo.png" style="width: 128px;">
    </div>

    <van-tabbar class="bottomBox" v-model="active" active-color="#ffffff" inactive-color="#ffffff">
      <van-tabbar-item @click="showNav(item)" :icon="item.icon" v-for="item in nav" :key="item.index">
        <span style="font-size: 0.38rem;">{{ item.name }}</span>
      </van-tabbar-item>
    </van-tabbar>

  </div>
</template>

<script>
import {Toast} from "vant";

export default {
  name: "tabbar",
  data() {
    return {
      active: 0,
      nav: [
        {to: '/mobile', icon: 'home-o', name: '首页', index: 0},
        {to: '#', icon: 'apps-o', name: '分类目录', index: 1},
        {to: '/mobile/newVideoList', icon: 'video-o', name: '最新视频', index: 2},
      ],
    }
  }, created() {
    let path = this.$route.path;
    this.nav.map(n => {
      if (n.to === path) {
        this.active = n.index;
      }
    })
  },
  mounted() {
    if (this.$root.userInfo && this.$root.userInfo.id) {
      this.nav.push({to: '/mobile/userManager', icon: 'gem-o', name: '个人中心', index: 3},)
    } else {
      this.nav.push({to: '#', icon: 'gem-o', name: '会员登陆', index: 3},)
    }
  }, methods: {
    showNav(item) {
      if (item) {
        if (item.index === 3) {
          if (!this.$root.userInfo || !this.$root.userInfo.id) {
            //未登陆
            let openPathMobile = "/mobile/userManager";
            localStorage.setItem("openPathMobile", openPathMobile);
            this.$root.userLoginDialogShowMobile = true;
            Toast("请先登陆");
          }else {
            localStorage.removeItem("pageNumber");
          }
        }
        if (item.index === 1) {
          this.$root.videoTypeBoxShow = !this.$root.videoTypeBoxShow
        } else {
          if (item.index === 2) {
            localStorage.removeItem("pageNumber");
          }
          this.$router.push(item.to)
        }
      }
    }
  },


}
</script>

<style scoped>
.bottomBox {
  background-color: #1b1f2a;
  height: 1.6rem;
}


</style>