<template>
  <div style="color: #ffffff;">
    <van-dialog close-on-click-overlay style="background-color: #242a37;" :show-confirm-button="false"
                v-model="$root.getPasswordDialogShowMobile">
      <div style="padding: 20px;">
        <div class="welcomeInfo">找回密码</div>

        <div>
          <input type="text" v-model="form.userName" maxlength="35" max="35" class="userInfoInputMobile"
                 placeholder="您的登录账号"/>
        </div>
        <div>
          <input type="password" v-model="form.password" maxlength="35" max="35" class="userInfoInputMobile"
                 placeholder="设置新密码"/>
        </div>
        <div>
          <input type="text" v-model="form.pin" maxlength="35" max="35" class="userInfoInputMobile"
                 placeholder="您的安全码"/>
        </div>

        <div style="margin-top: 10px;">
          <van-button block :disabled="submitBtnDisabled" type="warning" @click="setPassword">修改</van-button>
        </div>
        <div class="itemCenter" style="margin-top: 10px;">

          <div style="flex: 1;cursor: pointer;"  @click="$root.getPasswordDialogShowMobile=false;$root.userLoginDialogShowMobile=false;$root.userRegisterDialogShowMobile=true;form={}">
            新用户点此注册
          </div>
          <div style="flex: 1;cursor: pointer;text-align: right;"
               @click="$root.getPasswordDialogShowMobile=false;$root.userRegisterDialogShowMobile=false;$root.userLoginDialogShowMobile=true;form={}">
            点此登陆
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "getPasswordMobile",
  data(){
    return{
      submitBtnDisabled:false,
      form: {},
    }
  },methods:{
    setPassword() {
      request.post('/view/getPassword', this.form).then(res => {
        if (res.code === 200) {
          this.$toast.success(res.message);
          localStorage.removeItem("userInfo");
          this.$root.userLoginDialogShow=true;
          this.$root.userRegisterDialogShow=false;
          this.$root.getPasswordDialogShow=false;
        } else {
          this.$toast.fail(res.message);
          this.loadVerifCode();
        }
      })
    }
  }
}
</script>

<style scoped>
</style>