
<script type="text/javascript">
// 定义一些公共的属性和方法
const httpUrl = '//127.0.0.1:888'

// 暴露出这些属性
export default {
  httpUrl,
  getHMS,
}

export function getHMS(time) {
  const hour = parseInt(time / 3600) < 10 ? '0' + parseInt(time / 3600) : parseInt(time / 3600)
  const min = parseInt(time % 3600 / 60) < 10 ? '0' + parseInt(time % 3600 / 60) : parseInt(time % 3600 / 60)
  const sec = parseInt(time % 3600 % 60) < 10 ? '0' + parseInt(time % 3600 % 60) : parseInt(time % 3600 % 60)
  return hour + ':' + min + ':' + sec
}

export function formatDate (value,args) {
    var dt = new Date(value);
    if(args == 'yyyy-M-d') {// yyyy-M-d
        let year = dt.getFullYear();
        let month = dt.getMonth() + 1;
        let date = dt.getDate();
        return `${year}-${month}-${date}`;
    } else if(args == 'yyyy-M-d H:m:s'){// yyyy-M-d H:m:s
        let year = dt.getFullYear();
        let month = dt.getMonth() + 1;
        let date = dt.getDate();
        let hour = dt.getHours();
        let minute = dt.getMinutes();
        let second = dt.getSeconds();
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
    } else if(args == 'yyyy-MM-dd') {// yyyy-MM-dd
        let year = dt.getFullYear();
        let month = (dt.getMonth() + 1).toString().padStart(2,'0');
        let date = dt.getDate().toString().padStart(2,'0');
        return `${year}-${month}-${date}`;
    } else {// yyyy-MM-dd HH:mm:ss
        let year = dt.getFullYear();
        let month = (dt.getMonth() + 1).toString().padStart(2,'0');
        let date = dt.getDate().toString().padStart(2,'0');
        let hour = dt.getHours().toString().padStart(2,'0');
        let minute = dt.getMinutes().toString().padStart(2,'0');
        let second = dt.getSeconds().toString().padStart(2,'0');
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
    }
}

</script>
