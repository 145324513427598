<template>
  <div style="color: #ffffff;">
    <van-dialog close-on-click-overlay style="background-color: #242a37;" :show-confirm-button="false"
                v-model="$root.userLoginDialogShowMobile">
      <div style="padding: 20px;">
        <div class="welcomeInfo">会员登陆</div>
        <div>
          <input type="text" v-model="$root.from.userName" maxlength="35" max="35" class="userInfoInputMobile"
                 placeholder="请输入用户名"/>
        </div>
        <div>
          <input type="password" v-model="$root.from.password" maxlength="35" max="35" class="userInfoInputMobile"
                 placeholder="请输入密码"/>
        </div>
        <div style="margin-top: 10px;">
          <van-button block :disabled="submitBtnDisabled" type="warning" @click="onUserLogin">登录</van-button>
        </div>
        <div class="itemCenter" style="margin-top: 10px;">
          <div style="flex: 1;cursor: pointer;"
               @click="$root.userLoginDialogShowMobile=false;$root.userRegisterDialogShowMobile=true;form={}">
            新用户点此注册
          </div>
          <div
              @click="$root.getPasswordDialogShowMobile = true;$root.userLoginDialogShowMobile=false;$root.userRegisterDialogShowMobile=false;"
              style="flex: 1;text-align: right;cursor: pointer;">
            找回密码
          </div>
        </div>
      </div>
    </van-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";
import {Toast} from "vant";

export default {
  name: "loginMobile",
  data() {
    return {
      submitBtnDisabled: false,
    }
  }, mounted() {
  }, methods: {
    onUserLogin() {
      if (!this.checkForm()) {
        return;
      }
      request.post('/view/userLogin', this.$root.from).then(res => {
        if (res.code === 200) {
          Toast("登录成功");
          let userInfo = res.data;
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          this.$root.userInfo = userInfo;
          this.$root.userLoginDialogShowMobile = false;

          //跳转到个人中心
          sessionStorage.setItem("refresh", "1");
          if (this.$route.path === '/mobile/videoInfo') {
            this.$router.push("/mobile/recharge");
          } else {
            this.$router.push("/mobile/userManager");
          }
        } else {
          Toast.fail(res.message);
        }
      })
    },
    checkForm() {
      if (!this.$root.from.userName || this.$root.from.userName.length < 5 || this.$root.from.userName.length > 16) {
        Toast("用户名为5-16个字符");
        return false;
      }
      if (!this.$root.from.password || this.$root.from.password.length < 5) {
        Toast("密码不低于5个字符");
        return false;
      }
      return true;
    },

  }
}
</script>

<style scoped>

</style>