<template>
  <div>
    <div class="itemCenter">
      <div style="flex: 1;">
        <img style="cursor: pointer;" @click="$router.push('/')" src="@/assets/img/logo.png" class="pcLogo">
      </div>
      <div style="flex:1;">

        <div style="font-size: 15px;float: right;">
          <div class="itemCenter" v-if="$root.userInfo && $root.userInfo.userName">
            <div>
              <div v-if="!$root.userInfo.vipEndTime || $root.userInfo.vipDay<=0">
                <van-icon name="diamond-o"/>
                普通会员
              </div>
              <div v-if="$root.userInfo.vipDay && $root.userInfo.vipDay>0" style="text-align: center">
                <van-icon name="gem-o" />VIP剩余<span style="color: #EEB701FF">{{ $root.userInfo.vipDay }}</span>天
              </div>
            </div>
            &emsp;
            <div class="itemCenter">
              <div style="color: #F54B65;cursor: pointer;" @click="$router.push('/userManager')">
                <van-icon name="user-o"/> {{ $root.userInfo.userName }}
              </div>
            </div>
            &emsp;
            <div>
              <svg style="cursor: pointer;" @click="userLogout" t="1657872599033" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="3858" width="12" height="12">
                <path
                    d="M372.363636 558.545455 907.636364 558.545455 791.272727 744.727273 884.363636 744.727273 1024 512 884.363636 279.272727 791.272727 279.272727 907.636364 465.454545 372.363636 465.454545Z"
                    p-id="3859" fill="#F54B65"></path>
                <path
                    d="M0 1024 744.727273 1024 744.727273 721.454545 651.636364 721.454545 651.636364 930.909091 93.090909 930.909091 93.090909 93.090909 651.636364 93.090909 651.636364 302.545455 744.727273 302.545455 744.727273 0 0 0Z"
                    p-id="3860" fill="#F54B65"></path>
              </svg>
            </div>
          </div>


          <div v-else class="itemCenter">

            <div style="cursor: pointer;color: #F54B65"
                 @click="$root.userLoginDialogShow = true;">
              <van-icon
                  name="user-o"/>
              登录
            </div>
             &nbsp;
              <span style="color: #5C636C">/</span>
            &nbsp;
            <div style="cursor: pointer;color: #F54B65" @click="$root.userRegisterDialogShow = true;">注册</div>
          </div>
        </div>
      </div>

      <div>
        <van-overlay :show="$root.appShow" @click="$root.appShow = !$root.appShow">
          <div class="wrapper" style="text-align: center;padding-top: 10%;">
            <img src="@/assets/img/app.png" style="border: 1px solid #525252;margin: 0 auto;">
          </div>
        </van-overlay>
      </div>

    </div>
    <login/>
    <register/>
    <get-password/>
  </div>
</template>

<script>

import Login from "@/components/common/pc/login.vue";
import Register from "@/components/common/pc/register.vue";
import GetPassword from "@/components/common/pc/getPassword.vue";

export default {
  name: "pcHead",
  components: {GetPassword, Register, Login},
  data() {
    return {}
  }, mounted() {
  }, methods: {
    userLogout() {
      this.$root.userInfo = '';
      localStorage.removeItem("userInfo");
      localStorage.removeItem("token");
      this.$router.push("/");
    }
  }
}
</script>
<style scoped>

.pcLogo {
  margin-top: 10px;
  margin-bottom: 0;
  width: 100px;
}

</style>

