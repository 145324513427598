import { render, staticRenderFns } from "./registerMobile.vue?vue&type=template&id=7c793c08&scoped=true&"
import script from "./registerMobile.vue?vue&type=script&lang=js&"
export * from "./registerMobile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.3_vue-template-compiler@2.7.14_webpack@5.79.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c793c08",
  null
  
)

export default component.exports