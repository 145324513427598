<template>
  <div class="topBox">
    <van-row style="margin-top: 10px;margin-bottom: 10px;">
      <van-col span="12">
        <span class="like" @click="$router.push('/noticeList')">网站公告</span>&emsp;|&emsp;
        <span class="like" @click="$router.push('/issueList')">常见问题</span>&emsp;|&emsp;
<!--        <span class="like" @click="msgPc()">在线客服</span>-->
      </van-col>
      <van-col span="12">
        <div style="float: right;">
          <div class="boxItemsCenter">
            <svg t="1657012424246" class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                 p-id="1392"
                 height="16">
              <path
                  d="M625.834667 691.2h-256c-18.773333 0-34.133333 15.36-34.133334 34.133333s15.36 34.133333 34.133334 34.133334h256c18.773333 0 34.133333-15.36 34.133333-34.133334s-15.36-34.133333-34.133333-34.133333z"
                  p-id="1393" fill="#5C636C"></path>
              <path
                  d="M728.234667 102.4h-460.8c-56.490667 0-102.4 45.909333-102.4 102.4v614.4c0 56.490667 45.909333 102.4 102.4 102.4h460.8c56.490667 0 102.4-45.909333 102.4-102.4V204.8c0-56.490667-45.909333-102.4-102.4-102.4z m34.133333 716.8c0 18.773333-15.36 34.133333-34.133333 34.133333h-460.8c-18.773333 0-34.133333-15.36-34.133334-34.133333V204.8c0-18.773333 15.36-34.133333 34.133334-34.133333h460.8c18.773333 0 34.133333 15.36 34.133333 34.133333v614.4z"
                  p-id="1394" fill="#5C636C"></path>
            </svg>
            <div>
<!--              <span style="cursor: pointer;" @click="$root.appShow = true">APP下载</span> |-->
              <span class="like" @click="newDomain">最新网址</span>
            </div>
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import {Toast} from "vant";

export default {
  name: "pcTop",
  methods: {
    newDomain() {
      if (!this.$root.userInfo || !this.$root.userInfo.id) {
        localStorage.setItem("openPath", "/newDomain");
        this.$root.userLoginDialogShow = true;
        Toast("请登陆");
      } else {
        this.$router.push('/newDomain')
      }
    },
    msgPc() {
      if (!this.$root.userInfo || !this.$root.userInfo.id) {
        localStorage.setItem("openPath", "/msgPc");
        Toast("请先登陆");
        this.$root.userLoginDialogShow = true;
      } else {
        this.$router.push('/msgPc')
      }
    }
  }
}
</script>

<style scoped>
.topBox {
  font-size: 1px;
  color: #5C636C;
}

.like {
  cursor: pointer;
  font-size: 6px;
}
</style>