<template>
  <div>
    <router-view/>
    <navBottom/>
    <login-mobile/>
    <register-mobile/>
    <get-password-mobile/>
    <div class="backTop" @click="toTop" v-show="toShow">
      <img src="@/assets/img/top.png" class="topImg">
    </div>
  </div>
</template>

<script>
import navBottom from "@/views/mobile/nav/navBottom";
import Login from "@/components/common/pc/login.vue";
import Register from "@/components/common/pc/register.vue";
import GetPassword from "@/components/common/pc/getPassword.vue";
import LoginMobile from "@/components/common/mobile/loginMobile.vue";
import RegisterMobile from "@/components/common/mobile/registerMobile.vue";
import GetPasswordMobile from "@/components/common/mobile/getPasswordMobile.vue";
import request from "@/utils/request";

export default {
  name: "mobileLayout",
  components: {
    GetPasswordMobile,
    RegisterMobile,
    LoginMobile,
    navBottom
  }, data() {
    return {
      toShow: false,
    }
  }, mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  }, methods: {
    // 获取页面滚动距离
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 500) {
        this.toShow = true;
      }
    },
    toTop() {
      // 滚动到页面顶部
      window.scrollTo(0, 0);
      this.toShow = false;
    },
    isMobile() {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    },
  }, created() {
    //如果为pc端则跳转搭配pc首页
    if (!this.isMobile()) {
      this.$router.push('/');
    }

    if(this.$root.userInfo.id){
      request.post("/user/setIp");
    }
  }
}
</script>
<style scoped>
.backTop {
  z-index: 99999999;
  position: fixed;
  font-size: 0.6rem;
  width: 1rem;
  height: 1rem;
  border-radius: 0.3rem;
  bottom: 2.5rem;
  right: 0.3rem;
  cursor: pointer;
  color: red;
}
.topImg{
  width: 1rem;
  height: 1rem;
}
</style>
