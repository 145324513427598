<template>
  <div class="pcMainBox">
    <div style="width: 1280px;margin: 0 auto;">
      <pc-top/>
    </div>
    <div class="subjectBox">
      <div style="width: 1280px;margin: 0 auto;">
        <!--头部-->
        <pc-head/>
        <!--导航-->
        <pc-nav/>
        <!--主体-->
        <router-view/>
      </div>
    </div>
    <!--底部-->
    <bottom/>
    <div class="backTop" @click="toTop" v-show="toShow">
      <img src="@/assets/img/top.png" class="topImg">
    </div>
  </div>
</template>

<script>

import PcHead from "@/components/pc/pcHead";
import PcTop from "@/components/pc/pcTop";
import Bottom from "@/components/pc/bottom";
import PcNav from "@/components/pc/pcNav";
import request from "@/utils/request";

export default {
  name: "pcLayout",
  components: {PcNav, Bottom, PcTop, PcHead},
  data() {
    return {
      toShow: false,
      tag: this.$route.query.tag,
    }
  }, mounted() {
    if (this.tag) {
      localStorage.setItem("tag", this.tag);
    }
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  methods: {
    // 获取页面滚动距离
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 500) {
        this.toShow = true;
      } else {
        this.toShow = false;
        this.toShow = false;
      }
    },
    toTop() {
      window.scrollTo(0, 0);
      this.toShow = false;
    },
    isMobile() {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    },

  }, created() {
    if (this.isMobile()) {
      this.$router.push('/mobile/index');
    }

    if (this.$root.userInfo.id) {
      request.post("/user/setIp");
    }
  }
}


</script>

<style scoped>
.pcMainBox {
  background-color: #171B24;
  height: 100vh;
  color: #FFFAF5;
}

.subjectBox {
  width: 100%;
  background-color: #1b1f2a;
}

.backTop {
  z-index: 99999999;
  position: fixed;
  font-size: 14px;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.topImg {
  width: 22px;
  height: 22px;
}
</style>