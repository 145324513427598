<template>
  <div>
    <div>
      <van-row style="display: flex;align-items: center;">
        <van-col span="21">
          <div class="boxItemsCenter">
            <div v-for="videoType in videoTypeList" @click="selectType(videoType)" v-if="videoType.isShow"
                 :key="videoType.id"
                 :class="videoType.class" class="">
              {{ videoType.title }}
            </div>
          </div>
        </van-col>
        <van-col span="3">
          <div style="float: right">
            <div style="display: flex;align-items: center;background-color: #1e232f;font-size: 0.09rem;">
              <van-icon name="search" />
              <input v-model="title" type="text" max="20" maxlength="20" class="searchInputPc" :placeholder="placeholder">
              <button class="searchBtnGo" type="button" @click="searchVideoList" >Go</button>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>


  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "pcNav",
  data() {
    return {
      placeholder: '输入关键词搜索',
      title: '',
      videoTypeId: '',
      videoTypeList: [
        {
          id: 0,
          sort: 0,
          title: '首页',
          isShow: true,
          url: '/index',
          class: 'videoTypeTxt activeVideoTypeTxt',
        },


      ],
    }
  }, watch: {
    //监听路由地址的改变
    $route: {
      immediate: true,
      handler() {
        let videoTypeId = this.$route.query.videoTypeId
        if (videoTypeId) {//需要监听的参数
          this.videoTypeId = videoTypeId;
          this.setTypeNotActive();
          this.setTypeActive();
        } else {
          this.setTypeNotActive();
        }
      }
    }
  },
  mounted() {
    //获取分类
    this.getNav();
    //获取永久网址
    // this.getDomain();
  },
  methods: {
    getDomain(){
      request.post("/view/getDomain").then(res=>{
        if(res.code === 200){
          this.placeholder = '永久网址：'+res.message;
        }
      })
    },
    searchVideoList() {
      if (this.title && this.title.length>0) {
        this.$router.push('/searchVideo?title=' + this.title);
      }
    },
    setTypeNotActive() {
      this.videoTypeList.map((item) => {
        item.class = 'videoTypeTxt';
      })
    },
    setTypeActive() {
      this.videoTypeList.map((item) => {
        if (item.id == this.videoTypeId) {
          item.class = 'videoTypeTxt activeVideoTypeTxt';
        }
      });
    },
    selectType(videoType) {
      this.videoTypeId = videoType.id;
      this.setTypeNotActive();
      this.setTypeActive();

      localStorage.removeItem("pageNumber");

      this.$router.push({path: videoType.url, query: {videoTypeId: videoType.id}});
    },
    getNav() {
      request.get('/view/getNav').then(res => {
        res.data.map((item) => {
          item.class = 'videoTypeTxt';
          item.url = 'videoList';
          this.videoTypeList.push(item);
        })

        let newType ={
          id: 2,
              sort: 2,
            title: '最新视频',
            url: '/newVideoList',
            isShow: true,
            class: 'videoTypeTxt',
        };
        this.videoTypeList.push(newType);

        //设置导航样式
        this.setTypeNotActive();
        this.setTypeActive();
      });
    }
  }, created() {
    let videoTypeId = this.$route.query.videoTypeId;
    if (videoTypeId) {
      this.setTypeNotActive();
      this.setTypeActive();
    }
  }

}
</script>

<style scoped>
.videoTypeTxt {
  cursor: pointer;
  font-size: 8px;
  margin-top: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
}


.activeVideoTypeTxt {
  font-size: 10px;
  color: #F54B65;
  padding-bottom: 2px;
  border-bottom: 1px solid #F54B65;
}
.videoTypeTxt:hover{
  color: #F54B65;
}
.searchInputPc{
  width: 1rem;
  height: 15px;
  border: 0;
  background-color: #1e232f;
  padding: 1px;
}
.searchBtnGo{
  height: 16px;
  width: 20px;
  border: 0;
  background: linear-gradient(rgb(247, 131, 97) 0%, rgb(245, 75, 100) 100%);
  font-size: 8px;
  border-radius: 0 1px 1px 0;
  cursor: pointer;
}
</style>
