import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/global.css'
import '@vant/touch-emulator';
import 'amfe-flexible/index.min'
import '@/assets/js/globalCommon'
import VueClipboards from 'vue-clipboard2'

// 全局变量，不能修改
import globalVariable from './assets/js/globalVariable.js'

Vue.prototype.GLOBAL = globalVariable
// 全局方法
import globalFunction from './assets/js/globalFunction.js'

Vue.use(globalFunction)


import {
    Badge,
    Overlay,
    Divider,
    Popup,
    Search,
    NavBar,
    Tabbar,
    TabbarItem,
    Button,
    Col,
    Dialog,
    Empty,
    Field,
    Form,
    Icon,
    Image as VanImage,
    Lazyload,
    NoticeBar,
    Pagination,
    Radio,
    RadioGroup,
    Row,
    Swipe,
    SwipeItem,
    Tab,
    Tabs,
    Tag,
    Toast,
    CountDown,
    Uploader,
} from 'vant';
import request from "@/utils/request";

Vue.use(Badge);
Vue.use(Overlay);
Vue.use(Divider);
Vue.use(Popup);
Vue.use(Search);
Vue.use(NavBar);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Uploader);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(NoticeBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Dialog);
Vue.use(Pagination);
Vue.use(Empty);
Vue.use(Tag);
Vue.use(VanImage);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Field);
Vue.use(Button);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(CountDown);
Vue.use(VueClipboards);

Vue.config.productionTip = false
new Vue({
    router,
    data() {
        return {
            videoTypeBoxShow: false,
            searchBoxShow: false,
            userLoginDialogShow: false,
            userRegisterDialogShow: false,
            getPasswordDialogShow: false,
            userLoginDialogShowMobile: false,
            userRegisterDialogShowMobile: false,
            getPasswordDialogShowMobile: false,
            userInfo: {},
            from: {},
            appShow: false,
            adList: [],
            playIsShow: true,
        }
    }, mounted() {
        let userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
            this.userInfo = JSON.parse(userInfo);
        }
        //获取广告列表
        request.post('/view/adList').then(res => {
            this.adList = res.data;
        });
        this.getPlayIsShow();
        this.setRef();
    }, methods: {
        getPlayIsShow() {
            request.post('/view/getPlayIsShow').then(res => {
                this.playIsShow = res.data;
            })
        },
        setRef() {
            if (document.referrer) {
                request.post('/setRef/', {
                    ref: document.referrer,
                }).then(res => {
                })
            }
        }
    },
    store,
    render: h => h(App)
}).$mount('#app');


