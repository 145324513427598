import Vue from 'vue'
import VueRouter from 'vue-router'
import PcLayout from "@/layout/pcLayout";
import MobileLayout from "@/layout/mobileLayout";

Vue.use(VueRouter)

const routes = [
    {
        path: '/playReturn',
        name: 'PlayReturn',
        component: () => import("@/views/playReturn")
    },
    {
        path: '/',
        name: 'PcLayout',
        component: PcLayout,
        redirect: {name: 'Index'},
        children: [
            {
                path: '/pcPlayReturn',
                name: 'PcPlayReturn',
                component: () => import("@/views/pc/pcPlayReturn")
            },
            {
                path: '/index',
                name: 'Index',
                component: () => import("@/views/pc/index")
            }, {
                path: '/videoList',
                name: 'VideoList',
                component: () => import("@/views/pc/videoList")
            }, {
                path: '/newVideoList',
                name: 'NewVideoList',
                component: () => import("@/views/pc/newVideoList")
            }, {
                path: '/searchVideo',
                name: 'SearchVideo',
                component: () => import("@/views/pc/searchVideo")
            }, {
                path: '/videoInfo',
                name: 'VideoInfo',
                component: () => import("@/views/pc/videoInfo")
            }, {
                path: '/userRegister',
                name: 'UserRegister',
                component: () => import("@/views/pc/userRegister")
            }, {
                path: '/userLogin',
                name: 'UserLogin',
                component: () => import("@/views/pc/userLogin")
            }, {
                path: '/noticeList',
                name: 'NoticeList',
                component: () => import("@/views/pc/noticeList")
            }, {
                path: '/issueList',
                name: 'IssueList',
                component: () => import("@/views/pc/issueList")
            }, {
                path: '/userManager',
                name: 'UserManager',
                component: () => import("@/views/pc/user/userManager"),
                redirect: {name: 'Recharge'},
                children: [
                    {
                        path: '/recharge',
                        name: 'Recharge',
                        component: () => import("@/views/pc/user/recharge")
                    },
                    {
                        path: '/agent',
                        name: 'Agent',
                        component: () => import("@/views/pc/user/agent")
                    },
                    {
                        path: '/userPlay',
                        name: 'UserPlay',
                        component: () => import("@/views/pc/user/userPlayList")
                    },
                    {
                        path: '/userVideoCollection',
                        name: 'UserVideoCollection',
                        component: () => import("@/views/pc/user/userVideoCollection")
                    },
                    {
                        path: '/userAddMoney',
                        name: 'UserAddMoney',
                        component: () => import("@/views/pc/user/userAddMoneyList")
                    },
                    {
                        path: '/userExpend',
                        name: 'UserExpend',
                        component: () => import("@/views/pc/user/userExpend")
                    },
                    {
                        path: '/newDomain',
                        name: 'NewDomain',
                        component: () => import("@/views/pc/user/newDomain")
                    },
                    {
                        path: '/userProfit',
                        name: 'UserProfit',
                        component: () => import("@/views/pc/user/userProfit")
                    },
                    {
                        path: '/userWithdraw',
                        name: 'UserWithdraw',
                        component: () => import("@/views/pc/user/userWithdraw")
                    },
                    {
                        path: '/setUserWithdraw',
                        name: 'SetUserWithdraw',
                        component: () => import("@/views/pc/user/setUserWithdraw")
                    },
                    {
                        path: '/updatePassword',
                        name: 'UpdatePassword',
                        component: () => import("@/views/pc/user/updatePassword")
                    },
                    {
                        path: '/msgPc',
                        name: 'MsgPc',
                        component: () => import("@/views/pc/user/msgPc")
                    },
                ]
            },
        ]
    },
    {
        path: '/mobile/userRegister',
        name: 'MobileUserRegister',
        component: () => import("@/views/mobile/user/userRegister")
    }, {
        path: '/mobile/userLogin',
        name: 'MobileUserLogin',
        component: () => import("@/views/mobile/user/userLogin")
    }, {
        path: '/mobile/msgMobile',
        name: 'MobileMsgMobile',
        component: () => import('@/views/mobile/user/msgMobile')
    },
    {
        path: '/mobile',
        name: 'MobileLayout',
        component: MobileLayout,
        redirect: {name: 'Index'},
        children: [
            {
                path: '/mobilePlayReturn',
                name: 'MobilePlayReturn',
                component: () => import("@/views/mobile/view/mobilePlayReturn")
            },
            {
                path: '/mobile/index',
                name: 'MobileIndex',
                component: () => import('@/views/mobile/view/index')
            },
            {
                path: '/mobile/domainView',
                name: 'MobileDomainView',
                component: () => import('@/views/mobile/view/domainView')
            }, {
                path: '/mobile/videoType',
                name: 'MobileVideoType',
                component: () => import('@/views/mobile/view/videoType')
            }, {
                path: '/mobile/videoInfo',
                name: 'MobileVideoInfo',
                component: () => import('@/views/mobile/view/videoInfo')
            }, {
                path: '/mobile/userManager',
                name: 'MobileUserManager',
                component: () => import('@/views/mobile/user/userManager')
            }, {
                path: '/mobile/recharge',
                name: 'MobileRecharge',
                component: () => import('@/views/mobile/user/recharge')
            }, {
                path: '/mobile/noticeList',
                name: 'MobileNoticeList',
                component: () => import('@/views/mobile/user/noticeList')
            }, {
                path: '/mobile/issueList',
                name: 'MobileIssueList',
                component: () => import('@/views/mobile/user/issueList')
            }, {
                path: '/mobile/newDomain',
                name: 'MobileNewDomain',
                component: () => import('@/views/mobile/user/newDomain')
            }, {
                path: '/mobile/videoList',
                name: 'MobileVideoList',
                component: () => import('@/views/mobile/view/videoList')
            }, {
                path: '/mobile/videoAll',
                name: 'MobileVideoAll',
                component: () => import('@/views/mobile/view/videoAll')
            }, {
                path: '/mobile/searchVideoList',
                name: 'MobileSearchVideoList',
                component: () => import('@/views/mobile/view/searchVideoList')
            }, {
                path: '/mobile/newVideoList',
                name: 'MobileNewVideoList',
                component: () => import('@/views/mobile/view/newVideoList')
            }, {
                path: '/mobile/agent',
                name: 'MobileAgent',
                component: () => import('@/views/mobile/user/agent')
            }, {
                path: '/mobile/userPlayList',
                name: 'MobileUserPlayList',
                component: () => import('@/views/mobile/user/userPlayList')
            }, {
                path: '/mobile/userVideoCollection',
                name: 'MobileUserVideoCollection',
                component: () => import('@/views/mobile/user/userVideoCollection')
            }, {
                path: '/mobile/userAddMoney',
                name: 'MobileUserAddMoney',
                component: () => import('@/views/mobile/user/userAddMoneyList')
            }, {
                path: '/mobile/userExpend',
                name: 'MobileUserExpend',
                component: () => import('@/views/mobile/user/userExpend')
            }, {
                path: '/mobile/userProfit',
                name: 'MobileUserProfit',
                component: () => import('@/views/mobile/user/userProfit')
            }, {
                path: '/mobile/userWithdraw',
                name: 'MobileUserWithdraw',
                component: () => import('@/views/mobile/user/userWithdraw')
            }, {
                path: '/mobile/updatePassword',
                name: 'MobileUpdatePassword',
                component: () => import('@/views/mobile/user/updatePassword')
            }, {
                path: '/mobile/setUserWithdraw',
                name: 'MobileSetUserWithdraw',
                component: () => import('@/views/mobile/user/setUserWithdraw')
            },
        ]
    }, {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'hash',
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 全局路由导航拦截
router.beforeEach((to, from, next) => {
    // // 当前地址
    // let path = to.path;
    //
    // // 检查是否有token
    // let userInfo = sessionStorage.getItem("userInfo");
    // if (!userInfo && path.indexOf('/user/') !== 0) {
    //     //跳转到首页
    //     next({path: '/'})
    // }

    next();
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

function beforeFun(to, from, next) {


    // // 检查是否有token
    // let token = sessionStorage.getItem('token')
    // if (!token) {
    //     //跳转到登录页面
    //     next({path: '/login'})
    // }

}

function _isMobile() {
    return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
}

export default router
