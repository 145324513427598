import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title:'',
    videoId:'',
  },
  getters: {
  },
  mutations: {
    setTitle(state,title){
      localStorage.setItem("title",title);
      state.title = title;
    },
    setVideoId(state,videoId){
      state.videoId = videoId;
    }
  },
  actions: {
  },
  modules: {
  }
})
